import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import soundfile from '../assets/credits.mp3'
import Sound from 'react-sound'

import anguilla from '../assets/images/couple/anguilla.jpg'
import austin from '../assets/images/couple/austin.jpg'
import bigsur from '../assets/images/couple/bigsur.jpg'
import lbi from '../assets/images/couple/lbi.jpg'
import nye from '../assets/images/couple/nye.jpg'
import sanfran from '../assets/images/couple/sanfran.jpg'
import winery1 from '../assets/images/couple/winery1.jpg'
import winery2 from '../assets/images/couple/winery2.jpg'



import eat1 from '../assets/images/couple/eat1.jpg'
import eat2 from '../assets/images/couple/eat2.jpg'
import eat3 from '../assets/images/couple/eat3.jpg'
import eat4 from '../assets/images/couple/eat4.jpg'
import eat5 from '../assets/images/couple/eat5.jpg'
import eat6 from '../assets/images/couple/eat6.jpg'

import biking1 from '../assets/images/couple/biking1.jpg'
import biking2 from '../assets/images/couple/biking2.jpg'
import hiking1 from '../assets/images/couple/hiking1.jpg'
import hiking2 from '../assets/images/couple/hiking2.jpg'
import ski1 from '../assets/images/couple/ski1.jpg'
import ski2 from '../assets/images/couple/ski2.jpg'
import suit1 from '../assets/images/couple/suit1.jpg'
import suit2 from '../assets/images/couple/suit2.jpg'
import tuxedo from '../assets/images/couple/tuxedo.jpg'


import { Fade } from 'react-slideshow-image';


import cat1 from '../assets/images/couple/cat1.jpg'
import cat2 from '../assets/images/couple/cat2.jpg'
import cat4 from '../assets/images/couple/cat4.jpg'
import cat3 from '../assets/images/couple/cat3.jpg'
 
const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: false
}
 

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>S+J</title>
            <meta name="description" content="The couple" />
        </Helmet>

        <Sound url={soundfile} playStatus={Sound.status.PLAYING} />

        <div id="main" className="alt couple">
            <section id="one">
                <div className="inner">
                              <div class="titles">
                        <div class="titles__anchor">
                            <span class="titles__this">
                                This
                            </span>
                            <span class="titles__is">
                                Is
                            </span>
                            <span class="titles__us">
                                Us
                            </span>
                        </div>

                        </div>


                    <header className="major">
                        <h1>The Couple</h1>
          

                  
                    </header>

                      <h2>This is us traveling...</h2>
                      <Fade {...fadeProperties}>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={anguilla} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={austin} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={bigsur} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={lbi} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={winery2} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={nye} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={sanfran} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={winery1} alt="" />
                            </div>
                          </div>

                        </Fade>
<br/>
                      <h2>This is us eating...</h2>

                      <Fade {...fadeProperties}>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={eat1} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={eat2} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={eat3} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={eat4} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={eat5} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={eat6} alt="" />
                            </div>
                          </div>
                        </Fade>

<br/>
                      <h2>This is us playing...</h2>

                      <Fade {...fadeProperties}>
                            <div className="each-fade">
                            <div className="image-container">
                              <img src={tuxedo} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={biking1} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={hiking1} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={ski1} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={suit1} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={biking2} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={hiking2} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={ski2} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={suit2} alt="" />
                            </div>
                          </div>
                        </Fade>

<br/>
                      <h2>This is us “parenting” (<a href="https://www.instagram.com/axelandbirdie/">@axelandbirdie</a>)...</h2>
                    
                      <Fade {...fadeProperties}>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={cat1} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={cat2} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={cat3} alt="" />
                            </div>
                          </div>
                          <div className="each-fade">
                            <div className="image-container">
                              <img src={cat4} alt="" />
                            </div>
                          </div>
                        </Fade>
                        


                   </div>
            </section>
        </div>

    </Layout>
)

export default Generic